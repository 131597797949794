import axios from 'axios';
import { showAlert } from './alerts.js';

// Runs whenever a redirect occurs when on /selectBook page, works on onload events
export const backRefreshBrowserClosed = async () => {
  console.log('finish book refresh');
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/finishUserBookSelection',
    });
  } catch (error) {
    console.log('An error occured');
  }
};

export const removeCreditandGenericId = async (id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/removeCreditandGenericId/${id}`,
    });
  } catch (error) {
    console.log('An error occured');
  }
};

export const goBackToBookSelection = async (id) => {
  try {
    // Removed the start of the URL because the API and the website are using the same URL.
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/your-books/resetViewBookDate/${id}`,
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign('/selectBook?');
      }, 100);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
export const confirmRandomBookSelection = async (id) => {
  try {
    // Removed the start of the URL because the API and the website are using the same URL.
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/selectBook/${id}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book Selected!!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const returnBookToPool = async (id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/returnBookToPool/${id}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book info updated');
      window.setTimeout(() => {
        location.assign('/selectBook');
      }, 500);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const returnBook = async () => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/returnBook`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Book Removed!');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 1000);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};

export const resetRejectedBookList = async (url) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/resetRejectedBookList',
    });
    if (res.data.status === 'success') {
      showAlert('success', 'success');
      location.assign(url);
    }
  } catch (error) {
    showAlert('error', error.response.data.msg);
  }
};
